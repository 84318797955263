<template>
 <div :class="windowClass">
   <v-container>

        <v-row>
            <v-col cols="12">
              <report-per-agent :show="true" :area="SELECTED_AREA"/>
            </v-col>
        </v-row>
   </v-container>
  </div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
      report_type:'Overall',
      areas:[]
     // report_types:['Overall', 'Per Draw', 'Per Area'],
      
    }),
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
    },
    created() {
       if (this.$session.exists()) {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
          this.setLoggedIn(true) 
          this.getAreas()
        } 
    },
    computed:{
       windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
      TYPES(){
        if(this.$store.state.is_admin) {
          return ['Overall', 'Per Draw', 'Per Area']
        } else {
            return ['Overall', 'Per Draw', 'Per Account']
        }
      },
      SELECTED_AREA(){
        var areadID = this.$IsNum(this.$session.get('areaID'))
          let area =  this.areas.reduce((res, item) =>{
                    if(item.id == areadID) {
                        res = item
                    }
                    return res
                }, {})
          return area
      }
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
       getAreas(){
           this.$http.get("area/list",).then(response => {
            response.data.areas != null?this.areas = response.data.areas:this.areas =[]
            console.log(this.areas)
          }).catch(e => {
            console.log(e.data)
          })
      },
       
    }
  }
</script>